import inject from 'seacreature/lib/inject'
import { useContext } from 'react'
import ThemeCustomization from '../material/themes'

inject('pod', ({ RouterContext }) => {
  inject('app', () => {
    const Route = useContext(RouterContext)
    const NotFound = inject.one('404')

    return (
      <>
        <ThemeCustomization>{Route ? <Route /> : <NotFound />}</ThemeCustomization>
      </>
    )
  })
})
