import { createContext, useContext, useEffect, useState } from 'react'
import inject from 'seacreature/lib/inject'

inject('ctx', ({ HubContext }) => {
  const AppContext = createContext()

  const AppProvider = props => {
    const { children } = props
    const hub = useContext(HubContext)

    const [state, setState] = useState({
      draw: {
        open: true
      }
    })

    useEffect(() => {

    }, [])

    hub.on('toggle draw', ({ is_open }) => {
      return setState({
        ...state,
        draw: {
          open: !state.draw.open
        }
      })
    })

    return <AppContext.Provider value={state} children={children} />
  }

  inject('provider', AppProvider)

  return { AppContext, AppProvider }
})
