import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState } from 'react'
import { Button, IconButton, Grid, List, ListItemButton, ListItemText, TextField } from '@mui/material'
import MainLayout from '../material/layouts/main'
import MainCard from '../material/components/main-card'
import loading from '../material/components/loading'
import { DataGrid } from '@mui/x-data-grid'
import { ArrowForward, Send } from '@mui/icons-material/'

const { VITE_AUTHORISATION: AUTHORISATION, VITE_API: API } = import.meta.env

inject('pod', ({ StateContext, HubContext, AppContext }) => {
  inject('route', [
    '/masquerade',
    p => () => {
      const state = useContext(StateContext)
      const hub = useContext(HubContext)
      const app = useContext(AppContext)

      const columns = [
        { field: 'name', headerName: 'Identity Name', width: 220 },
        { field: 'email', headerName: 'Identity Email', width: 220 },
        {
          field: 'isapikey',
          headerName: 'Is API Key',
          width: 90,
          valueFormatter: ({ value }) => {
            return value ? '✅' : '❌'
          },
          align: 'center'
        }
      ]

      const endpoints = new Map([
        [
          'Invoice Headers',
          {
            call: {
              method: 'POST',
              action: '/v1/table/invoice'
            },
            params: [{ title: 'Company Code', value: 'companycode' }]
          }
        ],
        [
          'Invoice Lines',
          {
            call: {
              method: 'POST',
              action: '/v1/table/invoice_line'
            },
            params: [
              { title: 'Company Code', value: 'companycode' },
              { title: 'Document ID', value: 'documentid', parse: v => parseInt(v) }
            ]
          }
        ],
        [
          'Read Cursor',
          {
            call: {
              method: 'GET',
              action: '/v1/cursor/:cursorid'
            },
            params: [
              { title: 'Company Code', value: 'companycode' },
              { title: 'Cursor ID', value: 'cursorid' }
            ]
          }
        ]
      ])

      const [isloading, setloading] = useState(true)
      const [identities, setidentities] = useState([])
      const [selectedidentity, setselectedidentity] = useState({})
      const [masqueradeaccess, setmasqueradeaccess] = useState({})
      const [selectedendpoint, setselectedendpoint] = useState(0)
      const [display, setdisplay] = useState('')
      const [params, setparams] = useState({})

      useEffect(async () => {
        const { access } = await inject.one('access token')()
        const allidentitiesres = await fetch(`${API}/v1/getallidentities`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access,
            'Content-Type': 'application/json'
          }
        })
        const { identities } = await allidentitiesres.json()
        setidentities(identities)

        setloading(false)
      }, [])

      useEffect(async () => {
        if (!selectedidentity.id) return

        setloading(true)

        const { access } = await inject.one('access token')()
        const masqueraderes = await fetch(`${AUTHORISATION}/v1/masquerade`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: selectedidentity.id })
        })
        const { masqueradeaccess } = await masqueraderes.json()
        setmasqueradeaccess(masqueradeaccess)

        setloading(false)
      }, [selectedidentity])

      if (isloading) return loading()

      const startmasquerade = e => {
        e.preventDefault()
        return hub.emit('update', {
          task: 'masquerade',
          params: { ['masquerade']: { identity: selectedidentity } }
        })
      }

      const selectidentity = () => (
        <MainCard>
          <DataGrid
            rows={identities}
            columns={columns}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
            autoHeight
            onRowClick={({ row }) => setselectedidentity(row)}
            rowSelection={false}
          />
        </MainCard>
      )

      return (
        <MainLayout hub={hub} state={state} loading={isloading} app={app}>
          {state.task != 'masquerade' && (
            <p>
              <Button
                variant={selectedidentity.email ? 'contained' : 'outlined'}
                color='primary'
                endIcon={<ArrowForward />}
                disabled={!selectedidentity.email}
                onClick={startmasquerade}
              >
                {selectedidentity.email
                  ? `Masquerade as ${selectedidentity.email}`
                  : 'Select an identity to masquerade as...'}
              </Button>
            </p>
          )}

          {!state.params || !state.params['masquerade'].identity ? selectidentity() : null}

          {state.params && state.params['masquerade'].identity ? (
            <>
              <MainCard>
                {Array.from(endpoints.keys()).map((key, i) => (
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <ListItemButton
                        selected={i == selectedendpoint}
                        onClick={e => {
                          e.preventDefault()
                          setselectedendpoint(i)
                        }}
                      >
                        <ListItemText primary={key} />
                      </ListItemButton>
                    </Grid>
                    <Grid item xs={8}>
                      {endpoints.get(key).params.map(({ title, value }) => (
                        <span>
                          <TextField
                            id={`${key}-${title}`}
                            label={title}
                            onChange={e => {
                              e.preventDefault()
                              setparams({ ...params, [`${key}-${title}`]: e.target.value })
                            }}
                          />
                          &nbsp;
                        </span>
                      ))}
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={async e => {
                          e.preventDefault()
                          const { call, params: lparams } = endpoints.get(key)
                          const local = {}
                          for (const p of lparams)
                            local[p.value] = p.parse
                              ? p.parse(params[`${key}-${p.title}`])
                              : params[`${key}-${p.title}`]
                          const { method, action } = call
                          const companycode = local.companycode
                          delete local.companycode
                          let alter = action
                          for (const p of Object.keys(local)) {
                            alter = action.replace(`:${p}`, local[p])
                            if (action.indexOf(`:${p}`) >= 0) delete local[p]
                          }
                          const body = {}
                          for (const p of Object.keys(local)) body[p] = local[p]
                          const req = {
                            method,
                            headers: {
                              'X-Company-Code': companycode,
                              'X-Normans-Access-Token': masqueradeaccess,
                              'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                          }
                          method == 'GET' && delete req.body
                          const platformreq = await fetch(`${PLATFORM}${alter}`, req)
                          const disptext = await platformreq.text()
                          try {
                            setdisplay(JSON.stringify(JSON.parse(disptext), null, 2))
                          } catch {
                            setdisplay(disptext)
                          }
                        }}
                      >
                        <Send />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </MainCard>
              {display.length > 0 && (
                <>
                  <br />
                  <MainCard>
                    <TextField fullWidth sx={{ m: 1 }} value={display} multiline />
                  </MainCard>
                </>
              )}
            </>
          ) : null}
        </MainLayout>
      )
    }
  ])
})
