import inject from 'seacreature/lib/inject'
import jwt_decode from 'jwt-decode'
import { DateTime } from 'luxon'

const { VITE_AUTHORISATION: AUTHORISATION, VITE_KRATOS: KRATOS } = import.meta.env

inject('ctx', ({ hub }) => {
  const isexpired = (token, buffer = 10) => {
    try {
    const { exp } = jwt_decode(token)
    return exp < DateTime.now().toMillis() / 1000 - buffer
    } catch (e) {
      console.error(e)
      return true
    }
  }

  inject('access token', async () => {
    if (!AUTHORISATION) return { access: null }

    const refresh = localStorage.getItem('refresh')
    const access = localStorage.getItem('access')

    if (access && !isexpired(access)) return { access }

    if (refresh && !isexpired(refresh)) {
      console.log('refreshing access token', refresh)
      const accesstokenres = await fetch(`${AUTHORISATION}/v1/kratos/accesstoken`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'X-Refresh-Token': refresh
        },
        mode: 'cors',
        credentials: 'include'
      })

      const { token: accesstoken } = await accesstokenres.json()
      localStorage.setItem('access', accesstoken)
      return { access: accesstoken }
    }

    const pt1 = await fetch(`${KRATOS}/self-service/login/browser?refresh=true`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      credentials: 'include'
    })
    const { ui } = await pt1.json()
    const { action, method } = ui
    const pt2 = await fetch(action, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        provider: 'microsoft'
      })
    })
    const { redirect_browser_to } = await pt2.json()
    window.location = redirect_browser_to
  })
})
