import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState } from 'react'
import MainLayout from '../material/layouts/main'
import loading from '../material/components/loading'
import { DataGrid } from '@mui/x-data-grid'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { DateTime } from 'luxon'

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const { VITE_API: API } = import.meta.env

inject('pod', ({ StateContext, HubContext, AppContext }) => {
  inject('route', [
    '/allotracjobs',
    p => () => {
      const state = useContext(StateContext)
      const hub = useContext(HubContext)
      const app = useContext(AppContext)

      const [isloading, setloading] = useState(true)
      const [jobs, setjobs] = useState()
      const [showjobswithinvoices, setshowjobswithinvoices] = useState(false)
      const [showjobswithoutinvoices, setshowjobswithoutinvoices] = useState(true)
      const [searchterm, setssearchterm] = useState('')

      const [minDate, setMinDate] = useState()
      const [maxDate, setMaxDate] = useState()

      const [start, setstart] = useState()
      const [end, setend] = useState()

      useEffect(async () => {
        const { access } = await inject.one('access token')()

        const getdata = await fetch(`${API}/v1/accredo_allotrac_report`, {
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access
          }
        })
        const { jobs } = await getdata.json()
        let min_date = jobs[0].job_date
        let max_date = jobs[0].job_date
        for (const j of jobs) {
          j.job_id = j.job_id.toString()
          j.customername = j.client_name //j.invoices?.[0]?.customername ?? j.company_name ?? ''
          j.customername_lower = j.customername?.toLowerCase()
          if (min_date > j.job_date) min_date = j.job_date
          if (max_date < j.job_date) max_date = j.job_date
          j.job_date = DateTime.fromISO(j.job_date)
        }
        setjobs(jobs)

        setstart(DateTime.fromISO(min_date))
        setend(DateTime.fromISO(max_date))
        setMinDate(DateTime.fromISO(min_date))
        setMaxDate(DateTime.fromISO(max_date))

        setloading(false)
      }, [])

      if (isloading) return loading()

      const shouldcheckbox = j => {
        if (showjobswithinvoices && j.invoices.length > 0) return true
        if (showjobswithoutinvoices && j.invoices.length == 0) return true
        return false
      }

      const shoulddate = j => {
        if (start && j.job_date < start) return false
        if (end && j.job_date > end) return false
        return true
      }

      const term = searchterm.toLowerCase().trim()

      return (
        <MainLayout hub={hub} state={state} app={app}>
          <FormGroup
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <FormControlLabel
              control={
                <Checkbox checked={showjobswithinvoices} onChange={e => setshowjobswithinvoices(e.target.checked)} />
              }
              label='Has been invoiced'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showjobswithoutinvoices}
                  onChange={e => setshowjobswithoutinvoices(e.target.checked)}
                />
              }
              label='Missing invoice'
            />
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='en-gb'>
              <div style={{ marginRight: '16px' }}>
                <DatePicker
                  label='From'
                  value={start}
                  onChange={start => setstart(start)}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
              <div style={{ marginRight: '16px' }}>
                <DatePicker
                  label='Until'
                  value={end}
                  onChange={end => setend(end)}
                  minDate={minDate}
                  maxDate={maxDate}
                  style={{
                    marginRight: '16px'
                  }}
                />
              </div>
            </LocalizationProvider>
            <TextField
              label='Find Job ID, Customer...'
              variant='outlined'
              style={{
                width: '400px',
                marginRight: '16px'
              }}
              value={searchterm}
              onChange={e => setssearchterm(e.target.value)}
            />
          </FormGroup>
          <DataGrid
            rowHeight={24}
            getRowId={row => row.job_id}
            rows={jobs.filter(j => {
              if (!shouldcheckbox(j)) return false
              if (!shoulddate(j)) return false
              if (term != '') {
                if (j.job_id.startsWith(term)) return true
                if (j.customername_lower?.startsWith(term)) return true
              } else return true
              return false
            })}
            columns={[
              { field: 'job_id', headerName: 'Job ID', width: 70 },
              {
                field: 'job_date',
                headerName: 'Completion Date',
                valueFormatter: ({ value }) => value.toLocaleString(DateTime.DATE_SHORT)
              },
              { field: 'customername', headerName: 'Customer', width: 200 },
              { field: 'description', headerName: 'Description', width: 300 },
              { field: 'job_reference', headerName: 'Reference', width: 200 },
              {
                field: 'invoices',
                headerName: 'Invoiced',
                width: 300,
                type: 'string',
                valueGetter: ({ value }) => (value.length > 0 ? 'Yes' : 'No')
              }
            ]}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 30 } } }}
            pageSizeOptions={[30]}
            autoHeight
            // onCellClick={({ field, value }) => {
            //   if (['requestbody', 'responsebody'].includes(field))
            //     setdisplay(JSON.stringify(JSON.parse(value), null, 2))
            //   else if (['requestedat'].includes(field)) setdisplay(value)
            //   else setdisplay('')
            // }}
            rowSelection={false}
          />
        </MainLayout>
      )
    }
  ])
})
