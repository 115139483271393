import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { Box, Typography, TextField } from '@mui/material'
import MainLayout from '../material/layouts/main'
import MainCard from '../material/components/main-card'
import loading from '../material/components/loading'
import { DataGrid } from '@mui/x-data-grid'
import { DateTime } from 'luxon'

const { VITE_API: API } = import.meta.env

inject('pod', ({ StateContext, HubContext, AppContext }) => {
  inject('route', [
    '/',
    p => () => {
      const refresh = localStorage.getItem('refresh')
      if (refresh === 'null') localStorage.removeItem('refresh')

      const state = useContext(StateContext)
      const hub = useContext(HubContext)
      const app = useContext(AppContext)

      const columns = [
        { field: 'companycode', headerName: 'Company Code' },
        { field: 'requesturl', headerName: 'Request URL', width: 130 },
        { field: 'responsestatus', headerName: 'Response Status', width: 130 },
        {
          field: 'requestedat',
          headerName: 'Requested At',
          width: 130,
          valueFormatter: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
        },
        {
          field: 'responsetime',
          headerName: 'Response Time',
          width: 130,
          valueFormatter: ({ value }) => `${value}ms`
        },
        { field: 'requestbody', headerName: 'Request Body' },
        { field: 'responsebody', headerName: 'Response Body' }
      ]

      const [isloading, setloading] = useState(true)
      const [identity, setidentity] = useState({})
      const [auditdata, setauditdata] = useState([])
      const [display, setdisplay] = useState('')

      useEffect(async () => {
        const { access } = await inject.one('access token')()
        if (access) setidentity(jwt_decode(access))

        const auditdatares = await fetch(`${API}/v1/getauditdata`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access,
            'Content-Type': 'application/json'
          }
        })
        const auditdata = await auditdatares.json()
        const { rows } = auditdata
        setauditdata(
          rows.map(row => {
            const requestbody = JSON.stringify(row.requestbody)
            const responsebody = JSON.stringify(row.responsebody)
            const responsetime = DateTime.fromISO(row.respondedat).diff(
              DateTime.fromISO(row.requestedat),
              'milliseconds'
            ).milliseconds
            return {
              ...row,
              requestbody: row.requestbody ? requestbody : '',
              responsebody: row.responsebody ? responsebody : '',
              responsetime
            }
          })
        )

        setloading(false)
      }, [])

      if (isloading) return loading()

      return (
        <MainLayout hub={hub} state={state} loading={isloading} app={app}>
          <Box>
            <Typography variant='h2'>Hello {identity.email}</Typography>
            <Typography variant='h3'>Recent audit logs:</Typography>
          </Box>
          <br />

          <DataGrid
            rows={auditdata}
            columns={columns}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
            pageSizeOptions={[10, 100]}
            autoHeight
            onCellClick={({ field, value }) => {
              if (['requestbody', 'responsebody'].includes(field))
                setdisplay(JSON.stringify(JSON.parse(value), null, 2))
              else if (['requestedat'].includes(field)) setdisplay(value)
              else setdisplay('')
            }}
            rowSelection={false}
          />
          {display.length > 0 && (
            <>
              <br />
              <TextField fullWidth sx={{ m: 1 }} value={display} multiline />
            </>
          )}
        </MainLayout>
      )
    }
  ])
})
