import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState } from 'react'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Box,
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
  IconButton
} from '@mui/material'
import MainLayout from '../material/layouts/main'
import loading from '../material/components/loading'
import MainCard from '../material/components/main-card'
import { Create, Refresh, Delete } from '@mui/icons-material/'
import * as React from 'react'
import pwgen from 'generate-password-browser'

const { VITE_API: API } = import.meta.env

inject('pod', ({ StateContext, HubContext, AppContext }) => {
  inject('route', [
    '/apikeys',
    p => () => {
      const state = useContext(StateContext)
      const hub = useContext(HubContext)
      const app = useContext(AppContext)

      const [isloading, setloading] = useState(true)
      const [apikeys, setkeys] = useState(null)
      const [companycanditates, setcompanycanditates] = useState(null)
      const [rolecanditates, setrolecanditates] = useState(null)
      const [selectedcompanies, setselectedcompanies] = useState([])
      const [selectedroles, setselectedroles] = useState({})
      const [name, setname] = useState(null)
      const [email, setemail] = useState(null)

      useEffect(async () => {
        const { access } = await inject.one('access token')()

        const getapikeysres = await fetch(`${API}/v1/getapikeys`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access
          }
        })
        const { keys } = await getapikeysres.json()
        const processedkeys = keys.reduce((acc, { id, name, email, companyname, roles }) => {
          if (acc.has(id)) {
            const { name, email, companies } = acc.get(id)
            companies[companyname] = roles
            acc.set(id, { id, name, email, companies })
          } else acc.set(id, { id, name, email, companies: { [companyname]: roles } })
          return acc
        }, new Map())
        setkeys(processedkeys)

        const getcompaniesres = await fetch(`${API}/v1/getcompanies`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access
          }
        })
        const { companies } = await getcompaniesres.json()
        setcompanycanditates(companies)

        const getrolesres = await fetch(`${API}/v1/getroles`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access
          }
        })
        const { roles } = await getrolesres.json()
        setrolecanditates(roles)

        setloading(false)
      }, [selectedcompanies])

      if (isloading) return loading()

      const startcreateapikey = e => {
        e.preventDefault()
        hub.emit('update', { task: 'create api key', params: { 'create api key': { activestep: 0 } } })
      }
      const cancelapikeycreate = e => {
        e.preventDefault()
        setselectedcompanies([])
        setselectedroles({})
        hub.emit('update', { task: null })
      }
      const handleback = e => {
        e.preventDefault()
        hub.emit('update', {
          task: 'create api key',
          params: { 'create api key': { activestep: state.params['create api key'].activestep - 1 } },
          error: null
        })
      }
      const handlenext = e => {
        e.preventDefault()
        hub.emit('update', {
          task: 'create api key',
          params: { 'create api key': { activestep: state.params['create api key'].activestep + 1 } },
          error: null
        })
      }
      const handlecreate = async e => {
        e.preventDefault()
        const pw = document.getElementById('identitypassword').value
        console.log({
          selectedcompanies,
          selectedroles,
          name,
          email,
          password: document.getElementById('identitypassword').value
        })
        if (!selectedcompanies || selectedcompanies.length == 0)
          return hub.emit('update', {
            task: 'create api key',
            params: { 'create api key': { ...state.params['create api key'], activestep: 0 } },
            error: 'Please select at least one company'
          })
        if (!name || name.trim().length == 0)
          return hub.emit('update', {
            task: 'create api key',
            params: {
              'create api key': { ...state.params['create api key'], activestep: createapikeysteps.length - 1 }
            },
            error: 'Please enter a name'
          })
        if (!email || email.trim().length == 0)
          return hub.emit('update', {
            task: 'create api key',
            params: {
              'create api key': { ...state.params['create api key'], activestep: createapikeysteps.length - 1 }
            },
            error: 'Please enter an email'
          })
        if (!pw || pw.trim().length == 0)
          return hub.emit('update', {
            task: 'create api key',
            params: {
              'create api key': { ...state.params['create api key'], activestep: createapikeysteps.length - 1 }
            },
            error: 'Please enter a password'
          })

        const { access } = await inject.one('access token')()
        const newapikeyres = await fetch(`${API}/v1/newapikey`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            firstname: name.split(' ')[0],
            lastname: name.split(' ').slice(1).join(' '),
            email,
            password: pw,
            companies: selectedcompanies,
            roles: selectedroles
          })
        })
        if (newapikeyres.status == 400) {
          const errorsres = await newapikeyres.json()
          if ('isvalid' in errorsres) {
            delete errorsres.isvalid
            return hub.emit('update', {
              task: 'create api key',
              params: {
                'create api key': { ...state.params['create api key'], activestep: createapikeysteps.length - 1 }
              },
              error: Object.values(errorsres)[0][0]
            })
          } else {
            const { error } = errorsres
            return hub.emit('update', {
              task: 'create api key',
              params: {
                'create api key': { ...state.params['create api key'], activestep: createapikeysteps.length - 1 }
              },
              error
            })
          }
        }
        const { ok, error } = await newapikeyres.json()
        if (!ok)
          return hub.emit('update', {
            task: 'create api key',
            params: {
              'create api key': { ...state.params['create api key'], activestep: createapikeysteps.length - 1 }
            },
            error
          })

        hub.emit('update', { task: null, error: null })
      }
      const selectcompany = id => {
        setselectedcompanies(selectedcompanies => {
          if (selectedcompanies.includes(id)) selectedcompanies.splice(selectedcompanies.indexOf(id), 1)
          else selectedcompanies.push(id)
          return selectedcompanies
        })
        hub.emit('update', {})
      }
      const selectrole = (companyid, roleid) => {
        setselectedroles(selectedroles => {
          if (selectedroles[companyid]?.includes(roleid)) {
            selectedroles[companyid].splice(selectedroles[companyid].indexOf(roleid), 1)
          } else {
            if (selectedroles[companyid] == null) selectedroles[companyid] = []
            selectedroles[companyid].push(roleid)
          }
          return selectedroles
        })
        hub.emit('update', {})
      }

      const selectcompanystep = (
        <Box>
          <TextField
            id='companyfilter'
            label='Type to filter...'
            variant='outlined'
            onChange={e => {
              e.preventDefault()
              hub.emit('update', {
                task: 'create api key',
                params: { 'create api key': { ...state.params['create api key'], companyfilter: e.target.value } }
              })
            }}
          />
          <br />
          <br />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {companycanditates
              .filter(
                ({ description }) =>
                  state.params == null ||
                  state.params['create api key']?.companyfilter == null ||
                  description.toLowerCase().includes(state.params['create api key']?.companyfilter?.toLowerCase())
              )
              .map(({ id, description }) => (
                <span key={id}>
                  <Button
                    key={`btn_company_${id}`}
                    variant={selectedcompanies.includes(id) ? 'contained' : 'outlined'}
                    onClick={e => {
                      e.preventDefault()
                      selectcompany(id)
                    }}
                  >
                    {description}
                  </Button>
                  &nbsp;
                </span>
              ))}
          </Box>
        </Box>
      )

      const selectrolestep = companyid => {
        return (
          <Box>
            <TextField
              id='rolefilter'
              label='Type to filter...'
              variant='outlined'
              onChange={e => {
                e.preventDefault()
                hub.emit('update', {
                  task: 'create api key',
                  params: { 'create api key': { ...state.params['create api key'], rolefilter: e.target.value } }
                })
              }}
            />
            <br />
            <br />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {rolecanditates
                .filter(
                  ({ name }) =>
                    state.params == null ||
                    state.params['create api key']?.rolefilter == null ||
                    name.toLowerCase().includes(state.params['create api key']?.rolefilter?.toLowerCase())
                )
                .map(({ id: roleid, name: rolename }) => (
                  <span key={roleid}>
                    <Button
                      key={`btn_role_${companyid}_${roleid}`}
                      variant={selectedroles[companyid]?.includes(roleid) ? 'contained' : 'outlined'}
                      onClick={e => {
                        e.preventDefault()
                        selectrole(companyid, roleid)
                      }}
                    >
                      {rolename}
                    </Button>
                    &nbsp;
                  </span>
                ))}
            </Box>
          </Box>
        )
      }

      const generatepassword = e => {
        document.getElementById('identitypassword').value = pwgen.generate({
          length: 20,
          numbers: true,
          strict: true
        })
      }

      const deleteapikey = id => {
        ;(async () => {
          const { access } = await inject.one('access token')()
          await fetch(`${API}/v1/deleteapikey`, {
            method: 'POST',
            headers: {
              'X-Company-Code': 'Normans',
              'X-Normans-Access-Token': access,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
          })
          await hub.emit('update', { task: null, error: null })
        })()
        return <></>
      }

      const createidentitystep = (
        <Box>
          <div>
            <Typography>Name:</Typography>
            <TextField
              id='identityname'
              variant='outlined'
              onChange={e => {
                e.preventDefault()
                setname(e.target.value)
              }}
            />
          </div>
          <br />
          <div>
            <Typography>Email:</Typography>
            <TextField
              id='identityemail'
              variant='outlined'
              onChange={e => {
                e.preventDefault()
                setemail(e.target.value)
              }}
            />
          </div>
          <br />
          <div>
            <Typography>API Key Secret:</Typography>
            <TextField
              id='identitypassword'
              variant='outlined'
              defaultValue={state.params ? state.params[state.task]?.generatedpw : null}
            />
            &nbsp;
            <IconButton
              onClick={e => {
                e.preventDefault()
                generatepassword()
              }}
            >
              <Refresh />
            </IconButton>
          </div>
        </Box>
      )

      const createapikeysteps = [
        selectcompanystep,
        ...selectedcompanies.map(cid => selectrolestep(cid)),
        createidentitystep
      ]

      return (
        <MainLayout hub={hub} state={state} loading={isloading} app={app}>
          {state.task != 'create api key' && (
            <p>
              <Button variant='contained' color='primary' endIcon={<Create />} onClick={startcreateapikey}>
                Create API Key
              </Button>
            </p>
          )}
          {state.task === 'create api key' && (
            <MainCard>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={state.params[state.task].activestep} connector={null}>
                  <Step key={'Select Companies'}>
                    <StepLabel
                      optional={
                        state.params[state.task].activestep == 0 ? (
                          <Typography variant='caption' color='error'>
                            {state.error}
                          </Typography>
                        ) : null
                      }
                    >
                      Select Companies
                    </StepLabel>
                  </Step>
                  {selectedcompanies.map(cid => (
                    <Step key={cid}>
                      <StepLabel>Select Roles for {companycanditates.find(c => c.id == cid).description}</StepLabel>
                    </Step>
                  ))}
                  <Step key={'Create Identity'}>
                    <StepLabel
                      optional={
                        state.params[state.task].activestep == createapikeysteps.length - 1 ? (
                          <Typography variant='caption' color='error'>
                            {state.error}
                          </Typography>
                        ) : null
                      }
                    >
                      Create Identity
                    </StepLabel>
                  </Step>
                </Stepper>
                <br />
                {state.params[state.task].activestep === createapikeysteps.length ? (
                  <Box>
                    <Typography sx={{ mt: 2, mb: 1 }}>All steps completed</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={startcreateapikey}>Reset</Button>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    {createapikeysteps[state.params[state.task].activestep]}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button color='inherit' onClick={cancelapikeycreate}>
                        Cancel
                      </Button>
                      <Button
                        color='inherit'
                        disabled={state.params['create api key'].activestep == 0}
                        onClick={handleback}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={handlenext}
                        disabled={state.params['create api key'].activestep == createapikeysteps.length - 1}
                      >
                        Next
                      </Button>
                      <Button variant='outlined' color='primary' onClick={handlecreate} endIcon={<Create />}>
                        Create API Key
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </MainCard>
          )}
          {state.task === 'delete api key' && (
            <MainCard>
              <Box sx={{ width: '100%' }}>{deleteapikey(state.params[state.task].id)}</Box>
            </MainCard>
          )}
          <br />
          <Grid container spacing={2}>
            {Array.from(apikeys.entries()).map(([id, { name, email, companies }]) => (
              <Grid item xs={12} key={id}>
                <Accordion>
                  <AccordionSummary>
                    <Typography variant='h3'>
                      {name} - {email}
                    </Typography>
                  </AccordionSummary>
                  <AccordionActions>
                    <IconButton
                      color='error'
                      onClick={e => {
                        e.preventDefault()
                        hub.emit('update', { task: 'delete api key', params: { 'delete api key': { id } } })
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </AccordionActions>
                  <AccordionDetails>
                    {Object.entries(companies).map(([companyname, roles]) => (
                      <Box key={companyname}>
                        <Typography variant='h4'>Roles for {companyname}:</Typography>
                        <br />
                        {roles.map(role => (
                          <Box key={role}>
                            <Typography variant='h5'>{role}</Typography>
                          </Box>
                        ))}
                        <hr />
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        </MainLayout>
      )
    }
  ])
})
