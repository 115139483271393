import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState } from 'react'
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Button, Box, TextField } from '@mui/material'
import MainLayout from '../material/layouts/main'
import loading from '../material/components/loading'
import MainCard from '../material/components/main-card'
import { Create } from '@mui/icons-material/'
import mb from 'meatbag'

const { VITE_API: API } = import.meta.env

inject('pod', ({ StateContext, HubContext, AppContext }) => {
  inject('route', [
    '/companies',
    p => () => {
      const state = useContext(StateContext)
      const hub = useContext(HubContext)
      const app = useContext(AppContext)

      const [isloading, setloading] = useState(true)
      const [companies, setcompanies] = useState([])

      useEffect(async () => {
        const { access } = await inject.one('access token')()

        const companiesres = await fetch(`${API}/v1/getcompanies`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access
          }
        })
        const { companies } = await companiesres.json()
        setcompanies(companies)

        setloading(false)
      }, [])

      if (isloading) return loading()

      const startcreatecompany = e => {
        e.preventDefault()
        hub.emit('update', { task: 'create company', params: { 'create company': {} } })
      }

      const setname = name => {
        hub.emit('update', {
          task: 'create company',
          params: { 'create company': { ...state.params['create company'], name } }
        })
      }

      const setdescription = description => {
        hub.emit('update', {
          task: 'create company',
          params: { 'create company': { ...state.params['create company'], description } }
        })
      }

      const createcompany = async e => {
        e.preventDefault()
        const { name = '', description = '' } = state.params['create company']
        if (name.length == 0 || companies.map(r => r.name).includes(name))
          return hub.emit('update', {
            task: 'create company',
            params: { 'create company': { error: 'Company name taken' } }
          })

        if (description.length == 0)
          return hub.emit('update', {
            task: 'create company',
            params: { 'create company': { error: 'Description cannot be empty' } }
          })

        const { access } = await inject.one('access token')()
        await fetch(`${API}/v1/createcompany`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name,
            description
          })
        })
        await hub.emit('update', { task: null, error: null })
      }

      return (
        <MainLayout hub={hub} state={state} loading={isloading} app={app}>
          {state.task != 'create company' && (
            <p>
              <Button variant='contained' color='primary' endIcon={<Create />} onClick={startcreatecompany}>
                Create Company
              </Button>
            </p>
          )}
          {state.task == 'create company' && (
            <MainCard>
              <Box sx={{ width: '100%' }}>
                <div>
                  <Typography>Name:</Typography>
                  <TextField
                    id='companyname'
                    variant='outlined'
                    onChange={e => {
                      e.preventDefault()
                      setname(e.target.value)
                    }}
                  />
                  <br />
                  {state.params['create company'].error && (
                    <Typography variant='caption' color='error'>
                      {state.params['create company'].error}
                    </Typography>
                  )}
                  <br />
                  <Typography>Description:</Typography>
                  <TextField
                    id='companydescription'
                    variant='outlined'
                    onChange={e => {
                      e.preventDefault()
                      setdescription(e.target.value)
                    }}
                  />
                  <br />
                </div>
                <p>
                  <Button variant='contained' color='primary' endIcon={<Create />} onClick={createcompany}>
                    Create Company
                  </Button>
                </p>
              </Box>
            </MainCard>
          )}
          <br />
          {companies.map(({ id, name, description, numidentities, numroles }) => (
            <Grid item xs={12} key={`company_${id}`}>
              <Accordion>
                <AccordionSummary>
                  <Typography variant='h3'>{`${description} (${name})`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant='body1'>{`${mb.plural(
                    parseInt(numidentities),
                    'Attached Identity',
                    'Attached Identities'
                  )}`}</Typography>
                  <br />
                  <Typography variant='body1'>{`${numroles} Roles`}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </MainLayout>
      )
    }
  ])
})
