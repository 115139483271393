import inject from 'seacreature/lib/inject'
import { useContext, useEffect } from 'react'
import loading from '../material/components/loading'

const { VITE_AUTHORISATION: AUTHORISATION, VITE_API: API } = import.meta.env

inject('pod', ({ StateContext, HubContext }) => {
  inject('route', [
    '/loginsuccess',
    p => () => {
      useEffect(async () => {
        const refreshtokenres = await fetch(`${AUTHORISATION}/v1/kratos/refreshtoken`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true'
          },
          mode: 'cors',
          credentials: 'include'
        })
        const { ok, token: refresh } = await refreshtokenres.json()
        localStorage.setItem('refresh', refresh)
        const accesstokenres = await fetch(`${AUTHORISATION}/v1/kratos/accesstoken`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'X-Refresh-Token': refresh
          },
          mode: 'cors',
          credentials: 'include'
        })
        const { token: access } = await accesstokenres.json()
        await fetch(`${API}/v1/ensuremanagementuiaccess`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Refresh-Token': refresh,
            'X-Normans-Access-Token': access
          }
        })
        const accesstokenres2 = await fetch(`${AUTHORISATION}/v1/kratos/accesstoken`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'X-Refresh-Token': refresh
          },
          mode: 'cors',
          credentials: 'include'
        })
        const { token } = await accesstokenres2.json()
        localStorage.setItem('access', token)

        window.location = '/'
      }, [])

      return loading()
    }
  ])
})
