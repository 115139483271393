import NavCard from '../content/nav-card'
import Navigation from './navigation'
import SimpleBar from '../../../../components/simple-bar'

const Content = () => (
  <SimpleBar
    sx={{
      '& .simplebar-content': {
        display: 'flex',
        flexDirection: 'column'
      }
    }}
  >
    <Navigation />
    {/* <NavCard /> */}
  </SimpleBar>
)

export default Content