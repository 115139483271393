import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState } from 'react'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Paper,
  List,
  ListItemButton,
  IconButton,
  Button,
  Box,
  TextField
} from '@mui/material'
import MainLayout from '../material/layouts/main'
import loading from '../material/components/loading'
import MainCard from '../material/components/main-card'
import { Done, Clear, Create } from '@mui/icons-material/'

const { VITE_AUTHORISATION: AUTHORISATION, VITE_API: API } = import.meta.env

inject('pod', ({ StateContext, HubContext, AppContext }) => {
  inject('route', [
    '/roles',
    p => () => {
      const state = useContext(StateContext)
      const hub = useContext(HubContext)
      const app = useContext(AppContext)

      const [isloading, setloading] = useState(true)
      const [roles, setroles] = useState([])
      const [roledelta, setroledelta] = useState({})
      const [permissions, setpermissions] = useState([])

      useEffect(async () => {
        const { access } = await inject.one('access token')()

        const getrolesres = await fetch(`${API}/v1/getrolesdetailed`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access
          }
        })
        const { roles } = await getrolesres.json()
        setroles(roles)

        const getpermissionsres = await fetch(`${API}/v1/getpermissions`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access
          }
        })
        const { permissions } = await getpermissionsres.json()
        setpermissions(permissions)

        setloading(false)
      }, [])

      if (isloading) return loading()

      const saveroledata = async (roleid, permissionids) => {
        const { access } = await inject.one('access token')()

        await fetch(`${API}/v1/setrolepermissions`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ roleid, permissions: permissionids })
        })
        setroledelta(roledelta => {
          const newroledelta = { ...roledelta }
          delete newroledelta[roleid]
          return newroledelta
        })
      }

      const list = (roleid, permissionids, title) => (
        <Paper sx={{ width: 250, height: 230, overflow: 'auto', border: 'solid' }}>
          <br />
          <Typography variant='h4' sx={{ textAlign: 'center' }}>
            {title}
          </Typography>
          <hr />
          <List dense component='div' role='list'>
            {permissionids.map(pid => (
              <ListItemButton
                key={`${roleid}_permission_${pid}`}
                role='listitem'
                button='button'
                onClick={e => {
                  e.preventDefault()
                  setroledelta(roledelta => {
                    const newroledata = { ...roledelta }
                    const rolechanges = newroledata[roleid] ?? {
                      permissions: roles.find(r => r.roleid == roleid).permissions
                    }
                    if (title == 'Current Permissions')
                      rolechanges.permissions = rolechanges.permissions.filter(p => p != pid)
                    else rolechanges.permissions = rolechanges.permissions.concat([pid])
                    newroledata[roleid] = rolechanges
                    return newroledata
                  })
                }}
              >
                {permissions.find(p => p.id == pid).name}
              </ListItemButton>
            ))}
          </List>
        </Paper>
      )

      const startcreaterole = e => {
        e.preventDefault()
        hub.emit('update', { task: 'create role template', params: { 'create role template': {} } })
      }

      const setname = name => {
        hub.emit('update', { task: 'create role template', params: { 'create role template': { name } } })
      }

      const createrole = async e => {
        e.preventDefault()
        if (roles.map(r => r.name).includes(state.params['create role template'].name))
          return hub.emit('update', {
            task: 'create role template',
            params: { 'create role template': { error: 'Role name taken' } }
          })

        const { access } = await inject.one('access token')()
        await fetch(`${API}/v1/createroletemplate`, {
          method: 'POST',
          headers: {
            'X-Company-Code': 'Normans',
            'X-Normans-Access-Token': access,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: state.params['create role template'].name
          })
        })
      }

      return (
        <MainLayout hub={hub} state={state} loading={isloading} app={app}>
          {state.task != 'create role template' && (
            <p>
              <Button variant='contained' color='primary' endIcon={<Create />} onClick={startcreaterole}>
                Create Role
              </Button>
            </p>
          )}
          {state.task == 'create role template' && (
            <MainCard>
              <Box sx={{ width: '100%' }}>
                <div>
                  <Typography>Name:</Typography>
                  <TextField
                    id='rolename'
                    variant='outlined'
                    onChange={e => {
                      e.preventDefault()
                      setname(e.target.value)
                    }}
                  />
                  <br />
                  {state.params['create role template'].error && (
                    <Typography variant='caption' color='error'>
                      {state.params['create role template'].error}
                    </Typography>
                  )}
                </div>
                <p>
                  <Button variant='contained' color='primary' endIcon={<Create />} onClick={createrole}>
                    Create Role
                  </Button>
                </p>
              </Box>
            </MainCard>
          )}
          <br />
          {roles.map(({ roleid, rolename, permissions: rolepermissions }) => (
            <Grid item xs={12} key={roleid}>
              <Accordion>
                <AccordionSummary>
                  <Typography variant='h3'>{rolename}</Typography>
                </AccordionSummary>
                <AccordionActions>
                  <IconButton
                    color='primary'
                    disabled={!roledelta[roleid]}
                    onClick={e => {
                      e.preventDefault()
                      saveroledata(roleid, roledelta[roleid].permissions)
                    }}
                  >
                    <Done />
                  </IconButton>
                  <IconButton
                    color='error'
                    disabled={!roledelta[roleid]}
                    onClick={e => {
                      e.preventDefault()
                      setroledelta(roledelta => {
                        const newroledelta = { ...roledelta }
                        delete newroledelta[roleid]
                        return newroledelta
                      })
                    }}
                  >
                    <Clear />
                  </IconButton>
                </AccordionActions>
                <AccordionDetails>
                  <Grid container spacing={2} justifyContent='center' alignItems='center'>
                    <Grid item>
                      {list(
                        roleid,
                        roledelta[roleid] ? roledelta[roleid].permissions : rolepermissions,
                        'Current Permissions'
                      )}
                    </Grid>
                    <Grid item>
                      {list(
                        roleid,
                        permissions
                          .map(p => p.id)
                          .filter(
                            pid =>
                              !(roledelta[roleid] ? roledelta[roleid].permissions : rolepermissions).includes(pid)
                          ),
                        'Available Permissions'
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </MainLayout>
      )
    }
  ])
})
